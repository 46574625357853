import openSocket from "socket.io-client";
let socket = openSocket(process.env.REACT_APP_TOK_ROOT_URL);

function checkSocketConnectionAndReopen() {
  if (!socket.connected) {
    socket = openSocket(process.env.REACT_APP_TOK_ROOT_URL);
  }
}

function isSocketConnected() {
  return socket.connected;
}

function receiveNewMessage(callback) {
  socket.on("newMessage", (message) => {
    callback(message);
  });
}

function talkAsJoey(accessToken, roomId, callback) {
  socket.emit("talkAsJoey", { accessToken, roomId }, callback);
}

function sendClientTextMessageAsJoey(message, accessToken, callback) {
  socket.emit(
    "sendClientTextMessageAsJoey",
    { message, accessToken },
    callback
  );
}

function joinReceiveChatUpdates(accessToken, callback) {
  socket.emit("joinReceiveChatUpdates", { accessToken }, callback);
}

function receiveChatUpdates(callback) {
  socket.on("receiveChatUpdates", (update) => {
    callback(update);
  });
}

function leaveTalkAsJoey(accessToken, roomId, callback) {
  socket.emit("leaveTalkAsJoey", { accessToken, roomId }, callback);
}

function markChatForClosureAndExit(accessToken, roomId, callback) {
  socket.emit("markChatForClosureAndExit", { accessToken, roomId }, callback);
}

function sendNotificationToUserAsJoey(accessToken, roomId, callback) {
  socket.emit(
    "sendNotificationToUserAsJoey",
    { accessToken, roomId },
    callback
  );
}

function deleteClientTextMessageAsJoey(accessToken, messageId, callback) {
  socket.emit(
    "deleteClientTextMessageAsJoey",
    { messageId, accessToken },
    callback
  );
}

function broadcastChatUpdates(accessToken, roomId, callback) {
  socket.emit("broadcastChatUpdates", { roomId, accessToken }, callback);
}

function exitApplication() {
  socket.disconnect();
}

export {
  joinReceiveChatUpdates,
  receiveChatUpdates,
  talkAsJoey,
  leaveTalkAsJoey,
  sendClientTextMessageAsJoey,
  receiveNewMessage,
  markChatForClosureAndExit,
  exitApplication,
  sendNotificationToUserAsJoey,
  deleteClientTextMessageAsJoey,
  broadcastChatUpdates,
  checkSocketConnectionAndReopen,
  isSocketConnected,
};
