import React, { Component } from "react";
import { Feed } from "semantic-ui-react";
import Moment from "moment";

/**
 * Types
 * New Room - chat
 * toast.error(`New Chat Request from ${data.userName}`);
 * Close Chat - window close
 * toast.success(`Chat Close User Request - ${data.userName}`);
 * toast.success(`Chat Close Admin Request - ${data.userName}`);
 * New Message - rocketchat
 * toast.warn(`New Message - ${data.userName}`);
 */

function retrieveContentToDisplay(type, data) {
  const userName = data ? data.userName : "Test User";
  switch (type) {
    case "newRoom":
      // return `New Chat Request from ${userName}`;
      return `${userName}`;
    case "closeRoom":
      // return `Chat Close User Request - ${userName}`;
      return `${userName}`;
    case "updateRoom":
      // return `Chat Close Admin Request - ${userName}`;
      return `${userName}`;
    default:
      // return `New Message - ${userName}`;
      return `${userName}`;
  }
}

function getDuration(receivedAt) {
  return Moment(receivedAt).fromNow();
}

class NotificationContent extends Component {
  state = {
    duration: getDuration(this.props.receivedAt),
  };
  componentDidMount() {
    this.durationTimer = setInterval(() => {
      this.setState({ duration: getDuration(this.props.receivedAt) });
    }, 60000);
    // updates label every minute
  }
  componentWillUnmount() {
    clearInterval(this.durationTimer);
  }
  render() {
    const { props } = this;
    return (
      <Feed.Content>
        <Feed.Date content={this.state.duration} />
        <Feed.Summary>
          {retrieveContentToDisplay(props.type, props.data)}
        </Feed.Summary>
      </Feed.Content>
    );
  }
}

export default NotificationContent;
