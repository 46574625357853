import React from "react";
import { Label } from "semantic-ui-react";
import moment from "moment";

const RoomsDisplayPendingClosureLabel = (props) => {
  const { pendingClosure, closureDueDateTime } = props;
  if (pendingClosure && pendingClosure === true) {
    return (
      <Label tag color="red">
        Pending Closure @{" "}
        {moment(closureDueDateTime).format("DD MMM YYYY HH:mm")}
      </Label>
    );
  }
  return (
    <Label tag color="teal">
      Open Request
    </Label>
  );
};

export default RoomsDisplayPendingClosureLabel;
