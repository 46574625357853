import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";

import ChatTypeLabel from "../../chat/chat_type_label";

const dataConverter = (objectData) => {
  let convertedData = [];
  for (const key in objectData) {
    if (objectData.hasOwnProperty(key)) {
      convertedData.push({ id: key, value: objectData[key] });
    }
  }
  return convertedData;
};

const StatisticsTypeOfChatDistributionChart = (props) => {
  const tableData = dataConverter(props.data);
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Type of Chat</Table.HeaderCell>
          <Table.HeaderCell># of Chats</Table.HeaderCell>
          <Table.HeaderCell>Distribution %</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(tableData, (row, index) => {
          const { id, value } = row;
          const distributionValue = (
            value / props.totalNumOfChatsReceived
          ).toFixed(2);
          return (
            <Table.Row key={index}>
              <Table.Cell>
                <ChatTypeLabel type={id} />
              </Table.Cell>
              <Table.Cell>{value}</Table.Cell>
              <Table.Cell>{distributionValue}%</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default StatisticsTypeOfChatDistributionChart;
