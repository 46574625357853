import React, { Component } from "react";
import { Loader, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";

import { getAdminUserList } from "../../actions";

class UsersPage extends Component {
  componentDidMount() {
    const { accessToken } = this.props.user;
    this.props.getAdminUserList(accessToken);
  }
  render() {
    if (!this.props.userList) {
      return <Loader active />;
    }
    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Dept</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Is-Online</Table.HeaderCell>
            <Table.HeaderCell>Send Chat Email</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(this.props.userList, (user) => {
            const {
              _id,
              name,
              email,
              department,
              status,
              socketId,
              enableNewChatNotification,
            } = user;
            return (
              <Table.Row key={_id}>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{email}</Table.Cell>
                <Table.Cell>{department}</Table.Cell>
                <Table.Cell>{status}</Table.Cell>
                <Table.Cell>{socketId ? "Yes" : "No"}</Table.Cell>
                <Table.Cell>
                  {enableNewChatNotification ? enableNewChatNotification : "Y"}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    userList: state.user.adminUserList,
  };
};

export default connect(mapStateToProps, {
  getAdminUserList,
})(UsersPage);
