import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Grid } from "semantic-ui-react";

import ModalsUserCloseOpenChat from "../../component/modals/user_close_open_chat";
import Navigation from "../../component/navigation";
import MainPage from "./main_page";
import AddUserPage from "./add_user_page";
import UsersPage from "./users_page";
import ProfilePage from "./profile_page";
import RecordsPage from "./records_list_page";
import RecordsViewPage from "./records_view_page";
import StatisticsPage from "./statistics_page";
import LoaderOverlay from "../../component/loader";
import {
  getOpenRooms,
  addNewOpenRoom,
  deleteOpenRoom,
  leaveRoomToChat,
  updateOpenRoom,
  doLogout,
  addNewNotification,
  deleteNotification,
  updateNotification,
} from "../../actions";
import {
  joinReceiveChatUpdates,
  receiveChatUpdates,
  exitApplication,
  checkSocketConnectionAndReopen,
  isSocketConnected,
} from "../../utils/chat_api";

class IndexPage extends Component {
  state = {
    openUserCloseOpenChatModal: false,
    connectedToSocket: false,
  };
  componentDidMount() {
    // console.log('indexPage - component did mount')
    // kick user out if there is no user object - Unauthorized access
    if (!this.props.user) {
      this.props.history.push("/");
    } else {
      // check if all data is loaded?
      // to run all loads on config data before displaying UI.
      // openRooms,
      const { accessToken } = this.props.user;
      this.props.getOpenRooms(accessToken);

      this.handleSocketConnection();
      this.checkSocketConnectTimer = setInterval(
        () => this.handleSocketConnection(),
        5000
      );

      // start listening to events from websocket but joining these broadcasts channels.
      // channels: newRoomOpened, roomClosed
      joinReceiveChatUpdates(accessToken, (result, error) => {
        console.log(result, error);
        if (result) {
          this.props.updateNotification(result.notifications);
        }
      });

      receiveChatUpdates((update) => {
        // console.log('receiveChatUpdates->', update);
        const { type, data, notifications } = update;
        this.props.updateNotification(notifications);
        if (type === "newRoom") {
          this.props.addNewOpenRoom(data);
        } else if (type === "closeRoom") {
          if (
            !this.props.selectedRoomData ||
            data._id !== this.props.selectedRoomData._id
          ) {
            this.props.deleteOpenRoom(data);
          } else {
            this.props.deleteOpenRoom(data);
            this.props.leaveRoomToChat();
            this.setState({ openUserCloseOpenChatModal: true });
          }
        } else if (type === "updateRoom") {
          this.props.updateOpenRoom(data);
        }
      });
    }
  }
  componentWillUnmount() {
    // console.log('index page unmounting... deregister timer');
    clearInterval(this.checkSocketConnectTimer);
  }
  handleSocketConnection = () => {
    // console.log('checking socket connection ->', isSocketConnected())
    if (!isSocketConnected()) {
      this.setState({ connectedToSocket: false });
      // console.log('reconnecting socket connection ->', isSocketConnected())
      checkSocketConnectionAndReopen();
    } else {
      // console.log('socket is okay ->', isSocketConnected())
      this.setState({ connectedToSocket: true });
    }
  };
  handleLogout = () => {
    exitApplication();
    this.props.doLogout();
    this.props.history.push("/");
  };
  handleUrlChange = (url) => {
    this.props.history.push(url);
  };
  handleOpenUserCloseOpenChatModalClose = () => {
    this.setState({ openUserCloseOpenChatModal: false });
  };
  render() {
    return (
      <div style={{ padding: "5px 20px" }}>
        <Container fluid>
          <LoaderOverlay loading={!this.state.connectedToSocket} />
          <ModalsUserCloseOpenChat
            open={this.state.openUserCloseOpenChatModal}
            closeModal={this.handleOpenUserCloseOpenChatModalClose}
          />
          <Navigation
            handleLogout={this.handleLogout}
            handleUrlChange={this.handleUrlChange}
            connectedToSocket={this.state.connectedToSocket}
          />
          <Grid>
            <Grid.Column width={16}>
              <Switch>
                <Route exact path="/chatapp" component={MainPage} />
                <Route path="/chatapp/adduser" component={AddUserPage} />
                <Route path="/chatapp/users" component={UsersPage} />
                <Route path="/chatapp/profile" component={ProfilePage} />
                <Route exact path="/chatapp/records" component={RecordsPage} />
                <Route
                  exact
                  path="/chatapp/records/view/:recordId"
                  component={RecordsViewPage}
                />
                <Route path="/chatapp/statistics" component={StatisticsPage} />
              </Switch>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    selectedRoomData: state.rooms.selectedRoomData,
  };
}

export default connect(mapStateToProps, {
  getOpenRooms,
  addNewOpenRoom,
  deleteOpenRoom,
  leaveRoomToChat,
  updateOpenRoom,
  doLogout,
  addNewNotification,
  deleteNotification,
  updateNotification,
})(IndexPage);
