import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

const LoaderOverlay = (props) => {
  return (
    <Dimmer active={props.loading}>
      <Loader>
        Connecting to chat service...
        <p>
          Please refresh page if you have been waiting for more than 5 minutes
        </p>
      </Loader>
    </Dimmer>
  );
};

export default LoaderOverlay;
