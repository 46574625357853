import { DO_LOGIN_SUCCESS, GET_ADMIN_USER_LIST_SUCCESS } from "../actions";

const initialState = {
  data: null,
  adminUserList: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DO_LOGIN_SUCCESS:
      return Object.assign({}, state, { data: action.payload.data.adminuser });
    case GET_ADMIN_USER_LIST_SUCCESS:
      return Object.assign({}, state, {
        adminUserList: action.payload.data.adminuserlist,
      });
    default:
      return state;
  }
}
