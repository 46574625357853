import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_ROOT_URL;

// User Account Functions
export const DO_LOGIN_SUCCESS = "DO_LOGIN_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";
// Room/Request Functions
export const GET_OPEN_ROOMS_SUCCESS = "GET_OPEN_ROOMS_SUCCESS";
export const SELECT_ROOM_TO_CHAT = "SELECT_ROOM_TO_CHAT";
export const LEAVE_ROOM_TO_CHAT = "LEAVE_ROOM_TO_CHAT";
export const ADD_OPEN_ROOM_ON_THE_FLY = "ADD_OPEN_ROOM_ON_THE_FLY";
export const DELETE_OPEN_ROOM_ON_THE_FLY = "DELETE_OPEN_ROOM_ON_THE_FLY";
export const UPDATE_OPEN_ROOM_ON_THE_FLY = "UPDATE_OPEN_ROOM_ON_THE_FLY";
// Records Room Functions
export const GET_RECORDS_ROOMS_SUCCESS = "GET_RECORDS_ROOMS_SUCCESS";
// Admin User Functions
export const CREATE_NEW_ADMIN_USER_SUCCESS = "ADD_NEW_ADMIN_USER_SUCCESS";
export const GET_ADMIN_USER_LIST_SUCCESS = "GET_ADMIN_USER_LIST_SUCCESS";
export const UPDATE_ADMIN_USER_PASSWORD_SUCCESS =
  "UPDATE_ADMIN_USER_PASSWORD_SUCCESS";
// Statistics Functions
export const GET_STATISTICS_DATA_SUCCESS = "GET_STATISTICS_DATA_SUCCESS";
// Notification Functions
export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
// Error Functions
export const DO_API_ERROR_RESPONSE = "DO_API_ERROR_RESPONSE";

export function doLogin({ email, password }, callback) {
  const url = `${ROOT_URL}/admin/doLogin`;
  return axios
    .post(url, {
      email,
      password,
    })
    .then((response) => {
      callback(response.status, null);
      console.log(response);
      return {
        type: DO_LOGIN_SUCCESS,
        payload: response,
      };
    })
    .catch((error) => {
      console.log(error);
      callback(null, error);
      return {
        type: DO_API_ERROR_RESPONSE,
        payload: error,
      };
    });
}

export function doLogout() {
  return {
    type: USER_LOGOUT,
    payload: null,
  };
}

export function getOpenRooms(accessToken) {
  const url = `${ROOT_URL}/openRooms`;
  return axios({
    method: "GET",
    url,
    headers: { Accesstoken: accessToken },
  })
    .then((response) => {
      console.log("getOpenRooms", response);
      return {
        type: GET_OPEN_ROOMS_SUCCESS,
        payload: response,
      };
    })
    .catch((error) => {
      console.log("getOpenRooms", error);
      return {
        type: DO_API_ERROR_RESPONSE,
        payload: error.message,
      };
    });
}

export function selectRoomToChat(roomData) {
  return {
    type: SELECT_ROOM_TO_CHAT,
    payload: roomData,
  };
}

export function leaveRoomToChat() {
  return {
    type: LEAVE_ROOM_TO_CHAT,
    payload: null,
  };
}

export function addNewOpenRoom(roomData) {
  return {
    type: ADD_OPEN_ROOM_ON_THE_FLY,
    payload: roomData,
  };
}

export function deleteOpenRoom(roomData) {
  return {
    type: DELETE_OPEN_ROOM_ON_THE_FLY,
    payload: roomData,
  };
}

export function updateOpenRoom(roomData) {
  return {
    type: UPDATE_OPEN_ROOM_ON_THE_FLY,
    payload: roomData,
  };
}

export function createNewAdminUser({ accessToken, data }, callback) {
  const url = `${ROOT_URL}/admin/create`;
  data.department = "call-center";
  return axios({
    method: "POST",
    url,
    data,
    headers: { Accesstoken: accessToken },
  })
    .then((response) => {
      callback(response.status, null);
      return {
        type: CREATE_NEW_ADMIN_USER_SUCCESS,
        payload: response,
      };
    })
    .catch((error) => {
      console.log(error);
      callback(null, error);
      return {
        type: DO_API_ERROR_RESPONSE,
        payload: error,
      };
    });
}

export function getAdminUserList(accessToken) {
  const url = `${ROOT_URL}/admin/userList`;
  return axios({
    method: "GET",
    url,
    headers: { Accesstoken: accessToken },
  })
    .then((response) => {
      console.log("getOpenRooms", response);
      return {
        type: GET_ADMIN_USER_LIST_SUCCESS,
        payload: response,
      };
    })
    .catch((error) => {
      console.log("getOpenRooms", error);
      return {
        type: DO_API_ERROR_RESPONSE,
        payload: error.message,
      };
    });
}

export function updateAdminUser({ accessToken, data }, callback) {
  const url = `${ROOT_URL}/admin/update`;
  return axios({
    method: "POST",
    url,
    data,
    headers: { Accesstoken: accessToken },
  })
    .then((response) => {
      callback(response.status, null);
      return {
        type: UPDATE_ADMIN_USER_PASSWORD_SUCCESS,
        payload: response,
      };
    })
    .catch((error) => {
      console.log(error);
      callback(null, error);
      return {
        type: DO_API_ERROR_RESPONSE,
        payload: error.message,
      };
    });
}

export function getRecordsRooms({ searchType, searchValue, accessToken }) {
  const url = `${ROOT_URL}/rooms/records?searchType=${searchType}&searchValue=${searchValue}`;
  return axios({
    method: "GET",
    url,
    headers: { Accesstoken: accessToken },
  })
    .then((response) => {
      console.log("getRecordsRooms", response);
      return {
        type: GET_RECORDS_ROOMS_SUCCESS,
        payload: response,
      };
    })
    .catch((error) => {
      console.log("getRecordsRooms", error);
      return {
        type: DO_API_ERROR_RESPONSE,
        payload: error.message,
      };
    });
}

export function getStatisticsData({ accessToken, fromDate, toDate }, callback) {
  const url = `${ROOT_URL}/statistics/query?startDate=${fromDate}&endDate=${toDate}`;
  return axios({
    method: "GET",
    url,
    headers: { Accesstoken: accessToken },
  })
    .then((response) => {
      console.log("getStatisticsData", response);
      callback(response.status, null);
      return {
        type: GET_STATISTICS_DATA_SUCCESS,
        payload: response,
      };
    })
    .catch((error) => {
      console.log("getStatisticsData", error);
      callback(null, error);
      return {
        type: DO_API_ERROR_RESPONSE,
        payload: error.message,
      };
    });
}

export function addNewNotification(data) {
  return {
    type: ADD_NEW_NOTIFICATION,
    payload: data,
  };
}

export function deleteNotification(data) {
  return {
    type: DELETE_NOTIFICATION,
    payload: data,
  };
}

export function updateNotification(data) {
  return {
    type: UPDATE_NOTIFICATION,
    payload: data,
  };
}
