import React from "react";

const ChatTypeLabel = (props) => {
  const { type } = props;
  if (type === "6") {
    return (
      <span>
        I need another LOG as the hospital bill exceeded the guaranteed amount
      </span>
    );
  } else if (type === "5") {
    return <span>I want to check if my conditions or claims are payable</span>;
  } else if (type === "2") {
    return <span>Claim Enquiry</span>;
  } else if (type === "4") {
    return <span>eLOG Enquiry</span>;
  } else if (type === "7") {
    return <span>Other Enquiries</span>;
  } else {
    return <span>Unknown</span>;
  }
};

export default ChatTypeLabel;
