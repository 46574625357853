import React from "react";
import { Grid, Segment, Header } from "semantic-ui-react";

import StatisticsSummaryTable from "../../statistics/summary_table";
import StatisticsNumberOfChatsPerHourChart from "../../statistics/number_of_chats_per_hour_chart";
import StatisticsNumberOfChatsPerDayTable from "../../statistics/number_of_chats_per_day_table";
import StatisticsNumberOfChatsPerDayChart from "../../statistics/number_of_chats_per_day_chart";
import StatisticsTypeOfChatDistributionChart from "../../statistics/type_of_chat_distribution_chart";
import StatisticsSlaTable from "../../statistics/sla_table";

const StatisticsListTable = (props) => {
  return (
    <Grid columns={1}>
      <Grid.Column>
        <Header attached="top" block>
          SLA Report
        </Header>
        <Segment attached>
          <StatisticsSlaTable data={props.data.slaReportData} />
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Header attached="top" block>
          Summary
        </Header>
        <Segment attached>
          <StatisticsSummaryTable data={props.data.summaryData} />
          <StatisticsTypeOfChatDistributionChart
            data={props.data.typeOfChatData}
            totalNumOfChatsReceived={
              props.data.summaryData.totalNumOfChatsReceived
            }
          />
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Header attached="top" block>
          Chats Per Hour
        </Header>
        <Segment attached>
          <div style={{ height: 500 }}>
            <StatisticsNumberOfChatsPerHourChart
              data={props.data.chatPerHourData}
            />
          </div>
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Header attached="top" block>
          Chats Distribution By Day of Week
        </Header>
        <Segment attached>
          <div style={{ height: 500 }}>
            <StatisticsNumberOfChatsPerDayChart
              data={props.data.chatPerDayData}
            />
          </div>
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Header attached="top" block>
          Chats Per Date
        </Header>
        <Segment attached>
          <StatisticsNumberOfChatsPerDayTable
            data={props.data.chatPerDateData}
            totalNumOfChatsReceived={
              props.data.summaryData.totalNumOfChatsReceived
            }
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default StatisticsListTable;
