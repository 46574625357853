import React from "react";
import { Dropdown, Header, Message } from "semantic-ui-react";

const FormsInputsDropdown = (props) => {
  // console.log(field.value);

  const { field, form } = props;

  const handleChange = (e, { value }) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <div className="form-input-group">
      <div>
        <Header sub>{props.label}</Header>
        {props.secondaryLabel ? (
          <Header.Subheader>{props.secondaryLabel}</Header.Subheader>
        ) : (
          ""
        )}
      </div>
      <Dropdown
        fluid
        selection
        options={props.options}
        onChange={handleChange}
        value={field.value}
        disabled={props.disabled ? props.disabled : false}
      />
      {props.form.errors[props.field.name] && (
        <Message negative>{props.form.errors[props.field.name]}</Message>
      )}
    </div>
  );
};

export default FormsInputsDropdown;

// friendOptions = [
//   {
//     text: 'Jenny Hess',
//     value: 'Jenny Hess',
//     image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
//   },
//  ...
// ]
