import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import LoginPage from "./page/login";
import IndexPage from "./page/chatapp/index_page";
// import RegisterPage from './page/register/register_page';
// import ResetPasswordPage from './page/reset_password/reset_password_page';
// import ForgetPasswordPage from './page/forget_password/forget_password_page';
// import SysadminIndexPage from './page/sysadmin/index_page';

import "react-toastify/dist/ReactToastify.min.css";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={LoginPage} />
          <Route path="/chatapp" component={IndexPage} />
        </div>
      </Router>
    );
  }
}

export default App;
