import React from "react";
import { Table } from "semantic-ui-react";
import Moment from "moment";
import _ from "lodash";

const RecordsViewMessagesTable = (props) => {
  const { messages } = props;
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
          <Table.HeaderCell>Date Time</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(messages, (messageData) => {
          const { _id, message, userName, createdAt } = messageData;
          return (
            <Table.Row key={_id}>
              <Table.Cell>{userName}</Table.Cell>
              <Table.Cell>{message}</Table.Cell>
              <Table.Cell>
                {Moment(createdAt).format("DD/MM/YYYY HH:mm:ss")}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default RecordsViewMessagesTable;
