import React from "react";
import {
  Segment,
  Header,
  Item,
  Label,
  Button,
  Icon,
  Tab,
} from "semantic-ui-react";
import _ from "lodash";
import Moment from "moment";

import ChatTypeLabel from "../../chat/chat_type_label";
import RoomsDisplayPendingClosureLabel from "../display_pending_closure_label";
import NotificationLabel from "../../notification_bar/notification_label";

const enhanceOpenRoomsListWithNotifications = (openRooms, notifications) => {
  openRooms.forEach((room) => {
    const { uuid } = room;
    let notification = _.filter(notifications, (notification) => {
      return notification.roomId === uuid;
    });
    if (notification && notification.length === 1) {
      notification = notification[0];
      room.hasNewNotification = true;
      room.newNotificationType = notification.type;
    } else {
      room.hasNewNotification = false;
      room.newNotificationType = "";
    }
  });
  return openRooms;
};

const RoomsDisplayOpenRooms = (props) => {
  // console.log('------>', enhanceOpenRoomsListWithNotifications(props.openRooms, props.notifications));
  const combinedOpenRooms = enhanceOpenRoomsListWithNotifications(
    props.openRooms,
    props.notifications
  );
  const hasSelectedRoom = props.selectedRoomData ? true : false;
  const panes = [
    {
      menuItem: "Open",
      render: () => (
        <Tab.Pane>
          <Segment attached>
            <div
              style={{
                minHeight: "650px",
                maxHeight: "650px",
                overflowX: "hidden",
                overflowY: "scroll",
              }}>
              <Item.Group divided style={{ padding: "5px" }}>
                {_.map(combinedOpenRooms, (room) => {
                  const {
                    _id,
                    createdAt,
                    updatedAt,
                    type,
                    userName,
                    userEmail,
                    pendingClosure,
                    closureDueDateTime,
                    messages,
                    hasNewNotification,
                    newNotificationType,
                  } = room;
                  if (pendingClosure === false) {
                    return (
                      <Item key={_id}>
                        <Item.Content>
                          <Item.Header>
                            {hasNewNotification ? (
                              <NotificationLabel type={newNotificationType} />
                            ) : (
                              <Label circular color="yellow" empty />
                            )}
                            &nbsp;&nbsp;
                            <ChatTypeLabel type={type} />
                          </Item.Header>
                          <Item.Meta>
                            <Label>Since {Moment(createdAt).fromNow()}</Label>
                          </Item.Meta>
                          <Item.Extra>
                            <Button
                              fluid
                              primary
                              onClick={() => props.selectRoomToChat(room)}
                              disabled={hasSelectedRoom}>
                              <Icon name="talk" />
                              {userName} ({userEmail})
                            </Button>
                          </Item.Extra>
                        </Item.Content>
                      </Item>
                    );
                  }
                })}
              </Item.Group>
            </div>
          </Segment>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Pending Closure",
      render: () => (
        <Tab.Pane>
          <Segment attached>
            <Item.Group divided>
              {_.map(combinedOpenRooms, (room) => {
                const {
                  _id,
                  createdAt,
                  updatedAt,
                  type,
                  userName,
                  userEmail,
                  pendingClosure,
                  closureDueDateTime,
                } = room;
                if (pendingClosure === true) {
                  return (
                    <Item key={_id}>
                      <Item.Content>
                        <Item.Header>
                          <ChatTypeLabel type={type} />
                        </Item.Header>
                        <Item.Meta>
                          <RoomsDisplayPendingClosureLabel
                            pendingClosure={pendingClosure}
                            closureDueDateTime={closureDueDateTime}
                          />
                        </Item.Meta>
                        <Item.Description>
                          {userName} ({userEmail})
                        </Item.Description>
                        <Item.Extra>
                          <Button
                            primary
                            floated="right"
                            onClick={() => props.selectRoomToChat(room)}
                            disabled={hasSelectedRoom}>
                            Open
                            <Icon name="right chevron" />
                          </Button>
                          <Label>
                            {Moment(createdAt).format("DD-MM-YYYY HH:mm:ss")}
                          </Label>
                          <Label>
                            {Moment(updatedAt).format("DD-MM-YYYY HH:mm:ss")}
                          </Label>
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  );
                }
              })}
            </Item.Group>
          </Segment>
        </Tab.Pane>
      ),
    },
  ];
  return (
    <div>
      <Header attached="top" block>
        Open Chats
      </Header>
      <Tab panes={panes} />
    </div>
  );
};

export default RoomsDisplayOpenRooms;
