import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

// import VersionsReducer from './reducer_versions';
import UserReducer from "./reducer_user";
import RoomsReducer from "./reducer_rooms";
// import ClaimsReducer from './reducer_claim';
// import ClaimoptionsReducer from './reducer_claimoptions';
// import ClinicReducer from './reducer_clinic';
// import AppConfigReducer from './reducer_app_config';
// import SystemAdminReducer from './reducer_system_admin';
import StatisticsReducer from "./reducer_statistics";
import NotificationsReducer from "./reducer_notifications";

const appReducer = combineReducers({
  // state: (state = {}) => state
  // versions: VersionsReducer,
  user: UserReducer,
  rooms: RoomsReducer,
  statistics: StatisticsReducer,
  notifications: NotificationsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    Object.keys(state).forEach((key) => {
      storage.removeItem(`persist:${key}`);
    });
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
