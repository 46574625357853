import React from "react";
import { Message } from "semantic-ui-react";

const ChatTypeHeaderMessage = (props) => {
  const { type, optionalParameters } = props;
  if (type === "6") {
    return (
      <Message attached info>
        I need another LOG as the hospital bill exceeded the guaranteed amount
      </Message>
    );
  } else if (type === "5") {
    return (
      <Message attached info>
        I want to check if my conditions or claims are payable
      </Message>
    );
  } else if (type === "2") {
    return (
      <Message attached info>
        Claim Enquiry for Transaction No: {optionalParameters.claimId}
      </Message>
    );
  } else if (type === "4") {
    return (
      <Message attached info>
        eLOG Enquiry for eLOG No: {optionalParameters.elogId}
      </Message>
    );
  } else if (type === "7") {
    return (
      <Message attached info>
        Other enquiries
      </Message>
    );
  } else {
    return (
      <Message attached info>
        Unknown
      </Message>
    );
  }
};

export default ChatTypeHeaderMessage;
