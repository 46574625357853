import React, { Component } from "react";
import { Grid, Header, Segment, Image } from "semantic-ui-react";
import { connect } from "react-redux";

import FormsLogin from "../../component/forms/login";
import { doLogin } from "../../actions";

class LoginForm extends Component {
  componentDidMount() {
    if (this.props.user) {
      this.props.history.push("/chatapp");
    }
  }
  componentDidUpdate() {
    if (this.props.user) {
      this.props.history.push("/chatapp");
    }
  }
  render() {
    return (
      <div className="login-form">
        <style>{`
                    body > div,
                    body > div > div,
                    body > div > div > div.login-form {
                        height: 100%;
                    }
                    `}</style>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h1" textAlign="center">
              <Image
                src="https://mhcapp.mhc.asia/static/app_logo.png"
                size="huge"
              />
            </Header>
            <Header as="h2" textAlign="center">
              Log-in to your account
            </Header>
            <Segment stacked>
              <FormsLogin doLogin={this.props.doLogin} />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps, {
  doLogin,
})(LoginForm);
