import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { Button, Message, Grid, Divider } from "semantic-ui-react";

import FormsInputsText from "../../inputs/text/forms_inputs_text";
import FormsInputsDropdown from "../../inputs/dropdown";
import UpdateProfileSchema from "./validate";

const yesNoOptions = [
  { text: "Yes", value: "Y" },
  { text: "No", value: "N" },
];

class FormsUserUpdate extends Component {
  state = {
    error: false,
    errorMessage: "",
    success: false,
  };
  renderFormSubmissionResult() {
    if (this.state.success === true) {
      return (
        <Message positive>
          <Message.Header>Profile Updated Successfully!</Message.Header>
        </Message>
      );
    }
    if (this.state.error === true) {
      return (
        <Message negative>
          <Message.Header>
            We're sorry - there was an issue the update
          </Message.Header>
        </Message>
      );
    }
  }
  render() {
    return (
      <Formik
        initialValues={{
          name: this.props.user.name || "",
          email: this.props.user.email || "",
          newPassword: "",
          enableNewChatNotification:
            this.props.user.enableNewChatNotification || true,
        }}
        onSubmit={(values, actions) => {
          this.setState({ error: false, errorMessage: "", success: false });
          const { accessToken } = this.props.user;
          this.props.updateAdminUser(
            { accessToken, data: values },
            (result, error) => {
              if (error) {
                actions.resetForm();
                actions.setSubmitting(false);
                this.setState({
                  error: true,
                  errorMessage: error.errorMessage,
                });
              }
              if (result) {
                this.setState({ success: true });
              }
            }
          );
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={UpdateProfileSchema}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form style={{ padding: 20 }}>
            <Grid container columns={2}>
              <Grid.Column width={16}>
                <Divider horizontal>Update My Profile</Divider>
              </Grid.Column>
              <Grid.Column>
                <Field
                  name="name"
                  label="Name"
                  component={FormsInputsText}
                  secondaryLabel=""
                  disabled={true}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  name="email"
                  label="Email"
                  component={FormsInputsText}
                  secondaryLabel=""
                  disabled={true}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  name="newPassword"
                  label="New Password"
                  component={FormsInputsText}
                  secondaryLabel=""
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  name="enableNewChatNotification"
                  label="Enable New Chat Notification"
                  options={yesNoOptions}
                  component={FormsInputsDropdown}
                  secondaryLabel=""
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <Button
                  loading={isSubmitting}
                  type="submit"
                  primary
                  disabled={isSubmitting}
                  fluid
                  size="big">
                  Update My Profile
                </Button>
              </Grid.Column>
              <Grid.Column width={16}>
                {this.renderFormSubmissionResult()}
              </Grid.Column>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default FormsUserUpdate;
