import * as Yup from "yup";

const UpdateProfileSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, "Too short")
    .max(30, "Too long")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  newPassword: Yup.string()
    .trim()
    .min(8, "Too short minimum 8 characters")
    .max(30, "Too long maximum 30 characters")
    .matches(
      /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,30}$/,
      "Password must contain at least one letter and at least one number"
    )
    .required("New password is required"),
});

export default UpdateProfileSchema;
