import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { Button, Grid } from "semantic-ui-react";

import FormsInputsText from "../../inputs/text/forms_inputs_text";
import FormsInputDropdown from "../../inputs/dropdown";
import RecordsSearchSchema from "./validate";

class FormsRecordsSearch extends Component {
  render() {
    const searchTypeOptions = [{ text: "Requester Name", value: "userName" }];
    return (
      <Formik
        initialValues={{
          searchType: "userName",
          searchValue: "",
        }}
        onSubmit={(values, actions) => {
          console.log(values, actions);
          const { accessToken } = this.props;
          this.props.getRecordsRooms({ ...values, accessToken });
          actions.setSubmitting(false);
        }}
        validationSchema={RecordsSearchSchema}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Field
                    name="searchType"
                    options={searchTypeOptions}
                    label=""
                    secondaryLabel="Search Type"
                    component={FormsInputDropdown}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Field
                    name="searchValue"
                    label=""
                    component={FormsInputsText}
                    secondaryLabel="Search For Value"
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button
                    type="submit"
                    secondary
                    disabled={isSubmitting}
                    fluid
                    loading={isSubmitting}
                    size="massive">
                    Search Records
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default FormsRecordsSearch;
