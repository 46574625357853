import React, { Component } from "react";
import { Grid, Message } from "semantic-ui-react";
import { connect } from "react-redux";

import ChatMainChatScreen from "../../component/chat/main_chat_screen";
import RoomsDisplayOpenRooms from "../../component/rooms/display_open_rooms.js";
import NotificationBar from "../../component/notification_bar";
import { selectRoomToChat, leaveRoomToChat } from "../../actions";
import { broadcastChatUpdates } from "../../utils/chat_api";

class MainPage extends Component {
  selectRoomToOpen = (roomData) => {
    this.props.selectRoomToChat(roomData);
    broadcastChatUpdates(this.props.user.accessToken, roomData._id, () => {
      console.log(
        "broadcastChatUpdates",
        this.props.user.accessToken,
        roomData._id
      );
    });
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={5}>
          <RoomsDisplayOpenRooms
            openRooms={this.props.openRooms}
            selectRoomToChat={this.selectRoomToOpen}
            selectedRoomData={this.props.selectedRoomData}
            notifications={this.props.notifications}
          />
        </Grid.Column>
        <Grid.Column width={7}>
          {this.props.selectedRoomData ? (
            <ChatMainChatScreen
              selectedRoomData={this.props.selectedRoomData}
              user={this.props.user}
              leaveRoomToChat={this.props.leaveRoomToChat}
            />
          ) : (
            <Message info>Please click on one of the open chats.</Message>
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <NotificationBar
            notifications={this.props.notifications}
            selectRoomToChat={this.selectRoomToOpen}
            selectedRoomData={this.props.selectedRoomData}
            user={this.props.user}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    openRooms: state.rooms.openRooms,
    selectedRoomData: state.rooms.selectedRoomData,
    notifications: state.notifications.notifications,
  };
};

export default connect(mapStateToProps, {
  selectRoomToChat,
  leaveRoomToChat,
})(MainPage);
