import _ from "lodash";
import {
  GET_OPEN_ROOMS_SUCCESS,
  SELECT_ROOM_TO_CHAT,
  LEAVE_ROOM_TO_CHAT,
  ADD_OPEN_ROOM_ON_THE_FLY,
  DELETE_OPEN_ROOM_ON_THE_FLY,
  UPDATE_OPEN_ROOM_ON_THE_FLY,
  GET_RECORDS_ROOMS_SUCCESS,
} from "../actions";

const initialState = {
  openRooms: [],
  selectedRoomData: null,
  records: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_RECORDS_ROOMS_SUCCESS:
      return Object.assign({}, state, { records: action.payload.data.rooms });
    case GET_OPEN_ROOMS_SUCCESS:
      return Object.assign({}, state, { openRooms: action.payload.data.rooms });
    case SELECT_ROOM_TO_CHAT:
      return Object.assign({}, state, { selectedRoomData: action.payload });
    case LEAVE_ROOM_TO_CHAT:
      return Object.assign({}, state, { selectedRoomData: null });
    case ADD_OPEN_ROOM_ON_THE_FLY:
      return Object.assign({}, state, {
        openRooms: state.openRooms.concat(action.payload),
      });
    case DELETE_OPEN_ROOM_ON_THE_FLY:
      const newOpenRoomsArray = _.filter(
        state.openRooms,
        (room) => room._id !== action.payload._id
      );
      return Object.assign({}, state, { openRooms: newOpenRoomsArray });
    case UPDATE_OPEN_ROOM_ON_THE_FLY:
      const updateRoomId = action.payload._id;
      let updateOpenRoomsArray = _.filter(
        state.openRooms,
        (room) => room._id !== updateRoomId
      );
      updateOpenRoomsArray.push(action.payload);
      return Object.assign({}, state, { openRooms: updateOpenRoomsArray });
    default:
      return state;
  }
}
