import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Input, Header, Icon, Label } from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";

class FormsInputsDate extends Component {
  state = {
    startDate: this.props.field ? this.props.field.value : moment(),
  };

  handleChange(date) {
    this.setState({
      startDate: date,
    });
    this.props.form.setFieldValue(this.props.field.name, date);
  }

  render() {
    const { label, secondaryLabel, minDate, maxDate, disabled } = this.props;
    return (
      <div className="form-input-group">
        <div style={{ paddingBottom: 5 }}>
          <Header sub>{label || "Incurred Date"}</Header>
          <Header.Subheader>
            {secondaryLabel || "Input the date of purchase"}
          </Header.Subheader>
        </div>
        <DatePicker
          dateFormat="DD/MM/YYYY"
          customInput={
            <Input
              labelPosition="right"
              label={
                <Label>
                  <Icon name="calendar alternate outline" />
                </Label>
              }
            />
          }
          selected={this.state.startDate}
          onChange={this.handleChange.bind(this)}
          minDate={minDate ? minDate : null}
          maxDate={maxDate ? maxDate : null}
          disabled={disabled ? disabled : false}
        />
      </div>
    );
  }
}

export default FormsInputsDate;
