import React from "react";
import { Feed, Button, Icon } from "semantic-ui-react";

import NotificationLabel from "./notification_label";
import NotificationContent from "./notification_content";

const NotificationItem = (props) => {
  return (
    <Feed.Event style={{ padding: "5px 10px" }}>
      <Feed.Label>
        <NotificationLabel type={props.type} />
      </Feed.Label>
      <NotificationContent
        type={props.type}
        data={props.data}
        receivedAt={props.receivedAt}
      />
      {props.type !== "closeRoom" && props.type !== "updateRoom" ? (
        <Button
          icon
          labelPosition="right"
          disabled={props.hasSelectedRoom}
          onClick={() => props.selectRoomToChat(props.data)}>
          <Icon name="wechat" />
          Chat
        </Button>
      ) : null}
    </Feed.Event>
  );
};

export default NotificationItem;
