import _ from "lodash";

import {
  ADD_NEW_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "../actions";

const initialState = {
  notifications: [],
};

export default function(state = initialState, action) {
  let newNotificationsList = state.notifications.slice();
  // console.log('newNotificationsList',newNotificationsList);
  switch (action.type) {
    case ADD_NEW_NOTIFICATION:
      action.payload.receivedAt = new Date();
      newNotificationsList.push(action.payload);
      newNotificationsList = remapEntireNotificationList(newNotificationsList);
      return Object.assign({}, state, { notifications: newNotificationsList });
    case DELETE_NOTIFICATION:
      const targetRoomId = action.payload;
      newNotificationsList = _.filter(newNotificationsList, (notification) => {
        return notification.data._id !== targetRoomId;
      });
      return Object.assign({}, state, { notifications: newNotificationsList });
    case UPDATE_NOTIFICATION:
      return Object.assign({}, state, { notifications: action.payload });
    default:
      return state;
  }
}

function remapEntireNotificationList(notifications) {
  // console.log('remapEntireNotificationList', notifications);
  // const newNotificationList = notifications
  let newNotificationList = notifications.reduce(
    (accumulator, notification, index) => {
      const { data, type, receivedAt } = notification;
      const { _id } = data;
      accumulator[_id] = notification;
      return accumulator;
    },
    {}
  );
  // console.log('remapEntireNotificationList', newNotificationList);

  return _.values(newNotificationList);
}

/*
type: "",
receivedAt: "",
data: {
    uuid: "5e79b73edfdfc458148d4e1c"
    optionalParameters: {menuId: "6", actionId: "6a"}
    pendingClosure: false
    closureDueDateTime: null
    closedDateTime: null
    closedByUserName: null
    _id: "5e79b73edfdfc458148d4e1c"
    type: "7"
    userId: "5d3a5c04470aea61b7b7f0e6"
    userName: "Chang Li Nga UAT"
    userEmail: "linga@appsolutrends.com"
    status: 1
    messages: (2) [{…}, {…}]
    createdAt: 1585035070300
    updatedAt: 1585035070493
    __v: 1
}
 */
