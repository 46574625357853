import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { Button, Grid } from "semantic-ui-react";
import Moment from "moment";

// import FormsInputsText from '../../inputs/text/forms_inputs_text';
// import FormsInputDropdown from '../../inputs/dropdown';
import FormsInputDate from "../../inputs/date";
// import RecordsSearchSchema from './validate';

class FormsStatisticsSearch extends Component {
  render() {
    return (
      <Formik
        initialValues={{
          fromDate: this.props.startDate
            ? new Moment(this.props.startDate)
            : new Moment()
                .hour(0)
                .minute(0)
                .seconds(0)
                .millisecond(0),
          toDate: this.props.endDate
            ? new Moment(this.props.endDate)
            : new Moment()
                .hour(23)
                .minute(59)
                .seconds(59)
                .millisecond(999),
        }}
        onSubmit={(values, actions) => {
          console.log(values, actions);
          const { accessToken } = this.props;
          const formatFromDate = values.fromDate.valueOf();
          const formatToDate = values.toDate.valueOf();
          this.props.getStatisticsData(
            { fromDate: formatFromDate, toDate: formatToDate, accessToken },
            (result, error) => {
              actions.setSubmitting(false);
            }
          );
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          // Additional Validation (if any)
          let error = {};
          if (values.fromDate.isSameOrAfter(values.toDate)) {
            error.fromDate = "From Date cannot be after To Date";
          }
          return error;
        }}
        // validationSchema={RecordsSearchSchema}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Field
                    name="fromDate"
                    component={FormsInputDate}
                    label="From Date"
                    secondaryLabel="Select the start date"
                    maxDate={Moment()}
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Field
                    name="toDate"
                    component={FormsInputDate}
                    label="To Date"
                    secondaryLabel="Select the end date"
                    maxDate={Moment()}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button
                    type="submit"
                    secondary
                    disabled={isSubmitting}
                    fluid
                    loading={isSubmitting}
                    size="massive">
                    Display Statistics
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default FormsStatisticsSearch;
