import React from "react";
import { Table } from "semantic-ui-react";
import Moment from "moment";

import ChatTypeHeaderMessage from "../../chat/chat_type_header_message";

const RecordsViewInfoTable = (props) => {
  // console.log(props);
  const {
    uuid,
    optionalParameters,
    pendingClousure,
    closureDateTime,
    clousureDueDateTime,
    closedByUserName,
    type,
    userName,
    userEmail,
    createdAt,
    updatedAt,
  } = props.record;
  return (
    <Table celled fixed>
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={4}>Enquiry Type</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell colSpan={4}>
            <ChatTypeHeaderMessage
              type={type}
              optionalParameters={optionalParameters}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>ID</Table.Cell>
          <Table.Cell>{uuid}</Table.Cell>
          <Table.Cell>Status</Table.Cell>
          <Table.Cell>Closed</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Requester Email</Table.Cell>
          <Table.Cell>{userEmail}</Table.Cell>
          <Table.Cell>Requester Name</Table.Cell>
          <Table.Cell>{userName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Waiting For Closure</Table.Cell>
          <Table.Cell>{pendingClousure === true ? "Yes" : "No"}</Table.Cell>
          <Table.Cell>Request closure Date Time</Table.Cell>
          <Table.Cell>
            {closureDateTime
              ? Moment(closureDateTime).format("DD/MM/YYYY HH:mm:ss")
              : "None"}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Closure Due Date Time</Table.Cell>
          <Table.Cell>
            {clousureDueDateTime
              ? Moment(clousureDueDateTime).format("DD/MM/YYYY HH:mm:ss")
              : "None"}
          </Table.Cell>
          <Table.Cell>Closure Requested By</Table.Cell>
          <Table.Cell>{closedByUserName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Created At</Table.Cell>
          <Table.Cell>
            {Moment(createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </Table.Cell>
          <Table.Cell>Updated At</Table.Cell>
          <Table.Cell>
            {Moment(updatedAt).format("DD/MM/YYYY HH:mm:ss")}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default RecordsViewInfoTable;
