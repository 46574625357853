import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import AutoSizer from "react-virtualized-auto-sizer";

const numberDayToTextMapping = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const dataConverter = (objectData) => {
  let convertedData = [];
  for (const key in objectData) {
    if (objectData.hasOwnProperty(key)) {
      convertedData.push({
        id: numberDayToTextMapping[key],
        value: objectData[key],
      });
    }
  }
  return convertedData;
};

const StatisticsNumberOfChatsPerDayChart = (props) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height: 500, width }}>
          <ResponsiveBar
            data={dataConverter(props.data)}
            margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
            padding={0.3}
            colors={{ scheme: "nivo" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Day of Week",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "# of chats",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      )}
    </AutoSizer>
  );
};

export default StatisticsNumberOfChatsPerDayChart;
