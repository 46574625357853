import React, { Component } from "react";
import { connect } from "react-redux";

import FormsUserAdd from "../../component/forms/user/add";
import { createNewAdminUser } from "../../actions";

class AddUserPage extends Component {
  render() {
    return (
      <FormsUserAdd
        user={this.props.user}
        createNewAdminUser={this.props.createNewAdminUser}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps, {
  createNewAdminUser,
})(AddUserPage);
