import * as Yup from "yup";

const RecordsSearchSchema = Yup.object().shape({
  searchType: Yup.string().required(),
  searchValue: Yup.string()
    .required()
    .min(3),
});

export default RecordsSearchSchema;
