import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Header, Segment } from "semantic-ui-react";

import { getRecordsRooms } from "../../actions";
import FormsRecordsSearch from "../../component/forms/records/search";
import RecordsListTable from "../../component/records/list_table";

class RecordsListPage extends Component {
  componentDidMount() {
    // console.log(this.props);
    const { accessToken } = this.props.user;
    this.props.getRecordsRooms({
      searchType: "",
      searchValue: "",
      accessToken,
    });
  }
  render() {
    return (
      <Grid columns={1}>
        <Grid.Column>
          <Segment>
            <FormsRecordsSearch
              getRecordsRooms={this.props.getRecordsRooms}
              accessToken={this.props.user.accessToken}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Header attached="top" block>
            Records Display
          </Header>
          <Segment attached>
            <RecordsListTable records={this.props.records} />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    records: state.rooms.records,
  };
};

export default connect(mapStateToProps, {
  getRecordsRooms,
})(RecordsListPage);
