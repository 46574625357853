import React, { Component } from "react";
import {
  Segment,
  Header,
  Comment,
  Input,
  Button,
  Grid,
} from "semantic-ui-react";
import _ from "lodash";

import ChatTypeHeaderMessage from "../chat_type_header_message";
import { SpeechBubble } from "./speech_bubble";
import {
  receiveNewMessage,
  talkAsJoey,
  sendClientTextMessageAsJoey,
  leaveTalkAsJoey,
  markChatForClosureAndExit,
  sendNotificationToUserAsJoey,
  deleteClientTextMessageAsJoey,
} from "../../../utils/chat_api";

class ChatMainChatScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateTime: new Date(),
      roomId: this.props.selectedRoomData
        ? this.props.selectedRoomData._id
        : null,
      // messages: [],
      messageComponents: [],
      // initMenu: false,
      // menuItems: [],
      // loaded: false,
      // hasSelectedMenu: false,
      // hasOpenChat: false,
      // isClosedByUser: false,
      hasClickedSend: false,
      hasClickedClosure: false,
      hasClickedLeave: false,
      hasClickedSendNotification: false,
    };
  }
  componentDidMount() {
    const { accessToken, _id } = this.props.user;
    talkAsJoey(accessToken, this.state.roomId, (result, error) => {
      if (result) {
        const { previousMessages } = result;
        const newSpeechBubbleArray = _.map(previousMessages, (msg) => (
          <SpeechBubble
            key={msg.id}
            currentUserId={_id}
            message={msg}
            handleMessageDeleteClick={this.handleMessageDeleteClick}
          />
        ));
        this.setState({
          messageComponents: this.state.messageComponents.concat(
            newSpeechBubbleArray
          ),
        });
      }
    });

    receiveNewMessage((msg) => {
      // console.log(msg);
      const newSpeechBubbleComponent = (
        <SpeechBubble
          key={msg.messageId}
          currentUserId={_id}
          message={msg}
          handleMessageDeleteClick={this.handleMessageDeleteClick}
        />
      );
      this.setState({
        messageComponents: this.state.messageComponents.concat(
          newSpeechBubbleComponent
        ),
      });
    });
  }
  componentDidUpdate() {
    if (this.messageList) {
      this.scrollToBottom();
    }
  }
  scrollToBottom() {
    const scrollHeight = this.messageList.scrollHeight;
    const height = this.messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }
  handleMessageDeleteClick = (messageId) => {
    const { accessToken } = this.props.user;
    // console.log(messageId, accessToken);
    deleteClientTextMessageAsJoey(accessToken, messageId, () => {
      console.log("message deleted");
      let newMessageComponents = this.state.messageComponents.slice(0);
      newMessageComponents = _.remove(
        newMessageComponents,
        (message) => message.key !== messageId
      );
      this.setState({ messageComponents: newMessageComponents });
    });
  };
  handleSendMessageClick = (e) => {
    e.preventDefault();
    this.setState({ hasClickedSend: true });
    const messageText = this.inputRef.inputRef.value;
    const { accessToken } = this.props.user;
    if (messageText) {
      sendClientTextMessageAsJoey(messageText, accessToken, () => {
        console.log("message sent");
        this.setState({ hasClickedSend: false });
        this.inputRef.focus();
      });
    }
    this.inputRef.inputRef.value = "";
  };
  handleLeaveChat = () => {
    this.setState({ hasClickedLeave: true });
    const { accessToken } = this.props.user;
    const roomId = this.state.roomId;
    leaveTalkAsJoey(accessToken, roomId, (result, error) => {
      console.log(result, error);
      if (result) {
        // proceed to wipe down state selectedRoomData = null
        this.props.leaveRoomToChat();
      }
    });
  };
  handleMarkChatToClose = () => {
    this.setState({ hasClickedClosure: true });
    const { accessToken } = this.props.user;
    const roomId = this.state.roomId;
    markChatForClosureAndExit(accessToken, roomId, (result, error) => {
      console.log(result, error);
    });
  };
  handleSendNotificationToUser = () => {
    // console.log('clicked');
    this.setState({ hasClickedSendNotification: true });
    const { accessToken } = this.props.user;
    const roomId = this.state.roomId;
    sendNotificationToUserAsJoey(accessToken, roomId, (result, error) => {
      console.log(result, error);
      if (result) {
        this.setState({ hasClickedSendNotification: false });
      }
    });
  };
  render() {
    const {
      optionalParameters,
      type,
      pendingClosure,
    } = this.props.selectedRoomData;
    return (
      <div>
        <Header attached="top" block>
          Chatting with {this.props.selectedRoomData.userName} (
          {this.props.selectedRoomData.userEmail})
        </Header>
        <ChatTypeHeaderMessage
          optionalParameters={optionalParameters}
          type={type}
        />
        <div
          style={{ height: 450, overflowY: "scroll", overflowX: "hidden" }}
          ref={(a) => (this.messageList = a)}>
          <Segment attached>
            <Comment.Group>
              {_.map(this.state.messageComponents, (message) => message)}
            </Comment.Group>
          </Segment>
        </div>
        <Segment attached>
          <form onSubmit={this.handleSendMessageClick}>
            <Input
              fluid
              label={
                <Button type="submit" disabled={this.state.hasClickedSend}>
                  Send
                </Button>
              }
              ref={(c) => (this.inputRef = c)}
              labelPosition="right"
              disabled={!this.state.roomId}
            />
          </form>
        </Segment>
        <Segment attached>
          <Grid columns={2}>
            <Grid.Column>
              <Button
                fluid
                secondary
                onClick={this.handleLeaveChat}
                disabled={this.state.hasClickedLeave}>
                Leave Chat
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                color="red"
                onClick={this.handleMarkChatToClose}
                disabled={
                  pendingClosure ? pendingClosure : this.state.hasClickedClosure
                }>
                Mark Chat for Closure
              </Button>
            </Grid.Column>
            <Grid.Column width={16}>
              <Button
                fluid
                primary
                onClick={this.handleSendNotificationToUser}
                disabled={this.state.hasClickedSendNotification}>
                Send Notification To User
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default ChatMainChatScreen;
