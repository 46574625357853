import React from "react";
import Moment from "moment";
import { Comment, Button } from "semantic-ui-react";

export const SpeechBubble = (props) => {
  const { id, userId, userName, message, createdAt, messageId } = props.message;

  const currentSpeechMessageId = id ? id : messageId;

  const { currentUserId } = props;
  const isCurrentAdminMessage = userId === currentUserId ? true : false;
  let imageUrl = "https://react.semantic-ui.com/images/avatar/small/jenny.jpg";
  if (
    userName === "Rainbow" ||
    userName === "Joey" ||
    userName === "Groupcare"
  ) {
    imageUrl = "https://react.semantic-ui.com/images/avatar/small/matt.jpg";
  } else {
    imageUrl = "https://react.semantic-ui.com/images/avatar/small/elliot.jpg";
  }
  return (
    <Comment>
      <Comment.Avatar src={imageUrl} />
      <Comment.Content>
        <Comment.Author as="a">{userName || "Unknown"}</Comment.Author>
        <Comment.Metadata>
          <div>{`${Moment(createdAt).format(
            "Do MMM YYYY hh:mm:ss a"
          )} (${Moment(createdAt).fromNow()})`}</div>
        </Comment.Metadata>
        <Comment.Text style={{ textTransform: "capitilize" }}>
          {message}
        </Comment.Text>
        {isCurrentAdminMessage === true ? (
          <Comment.Actions>
            <Comment.Action>
              <Button
                onClick={() =>
                  props.handleMessageDeleteClick(currentSpeechMessageId)
                }
                basic>
                Delete
              </Button>
            </Comment.Action>
          </Comment.Actions>
        ) : null}
      </Comment.Content>
    </Comment>
  );
};
