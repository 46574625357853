import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { Button, Message } from "semantic-ui-react";

import FormsInputsText from "../inputs/text/forms_inputs_text";
import FormsInputPassword from "../inputs/password/forms_inputs_password";
import LoginSchema from "./validate";

class FormsLogin extends Component {
  state = {
    error: false,
    errorMessage: "",
  };
  renderFormSubmissionResult() {
    if (this.state.error === true) {
      return (
        <Message negative>
          <Message.Header>
            We're sorry - there was an issue with your credentials
          </Message.Header>
        </Message>
      );
    }
  }
  render() {
    return (
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={(values, actions) => {
          // console.log(values, actions);
          this.setState({ error: false, errorMessage: "" });
          this.props.doLogin(values, (result, error) => {
            if (error) {
              actions.resetForm();
              actions.setSubmitting(false);
              this.setState({ error: true, errorMessage: error.errorMessage });
            }
          });
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={LoginSchema}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <Field
              name="email"
              label=""
              component={FormsInputsText}
              secondaryLabel="Email Address"
            />
            &nbsp;
            <Field
              name="password"
              label=""
              component={FormsInputPassword}
              secondaryLabel="Password"
            />
            &nbsp;
            <Button
              type="submit"
              primary
              disabled={isSubmitting}
              fluid
              loading={isSubmitting}>
              Log In
            </Button>
            {this.renderFormSubmissionResult()}
          </Form>
        )}
      />
    );
  }
}

export default FormsLogin;
