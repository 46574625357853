import React, { Component } from "react";
import { connect } from "react-redux";

import FormsUserUpdate from "../../component/forms/user/profile";
import { updateAdminUser } from "../../actions";

class ProfilePage extends Component {
  render() {
    console.log(this.props.user);
    return (
      <FormsUserUpdate
        user={this.props.user}
        updateAdminUser={this.props.updateAdminUser}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps, {
  updateAdminUser,
})(ProfilePage);
