import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Header, Segment, Message } from "semantic-ui-react";

import { getStatisticsData } from "../../actions";
// import FormsRecordsSearch from '../../component/forms/records/search';
// import RecordsListTable from '../../component/records/list_table';
import FormsStatisticsSearch from "../../component/forms/statistics/search";
import StatisticsListTable from "../../component/statistics/list_table";

class StatisticsPage extends Component {
  componentDidMount() {
    // console.log(this.props);
    // const {accessToken} = this.props.user;
    // this.props.getRecordsRooms({searchType: '', searchValue: '', accessToken});
  }
  render() {
    console.log("statsData", this.props.statisticsData);
    return (
      <Grid columns={1}>
        <Grid.Column>
          <Segment>
            <FormsStatisticsSearch
              startDate={
                this.props.statisticsData
                  ? this.props.statisticsData.searchStartDateValue
                  : null
              }
              endDate={
                this.props.statisticsData
                  ? this.props.statisticsData.searchEndDateValue
                  : null
              }
              accessToken={this.props.user.accessToken}
              getStatisticsData={this.props.getStatisticsData}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Header attached="top" block>
            Statistics Result
          </Header>
          <Segment attached>
            {this.props.statisticsData ? (
              <StatisticsListTable data={this.props.statisticsData} />
            ) : (
              <Message info>
                Please select a date range to display statistics result.
              </Message>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    statisticsData: state.statistics.statsData,
  };
};

export default connect(mapStateToProps, {
  getStatisticsData,
})(StatisticsPage);
