import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import Moment from "moment";
import { Link } from "react-router-dom";

import ChatTypeLabel from "../../chat/chat_type_label";

const RecordsListTable = (props) => {
  const { records } = props;
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Requester</Table.HeaderCell>
          <Table.HeaderCell>Closed By</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Enquiry Type</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(records, (record) => {
          const { uuid, type, userName, closedByUserName, createdAt } = record;
          return (
            <Table.Row key={uuid}>
              <Table.Cell>
                <Link to={`/chatapp/records/view/${uuid}`}>{uuid}</Link>
              </Table.Cell>
              <Table.Cell>{userName}</Table.Cell>
              <Table.Cell>{closedByUserName}</Table.Cell>
              <Table.Cell>Closed</Table.Cell>
              <Table.Cell>
                <ChatTypeLabel type={type} />
              </Table.Cell>
              <Table.Cell>
                {Moment(createdAt).format("DD/MM/YYYY HH:mm")}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default RecordsListTable;
