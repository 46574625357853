import React from "react";
import { Icon } from "semantic-ui-react";

/**
 * Types
 * New Room - chat
 * Close Chat - window close
 * New Message - rocketchat
 */

const NotificationLabel = (props) => {
  switch (props.type) {
    case "newRoom":
      return <Icon name="chat" color="green" />;
    case "closeRoom":
      return <Icon name="window close" color="red" />;
    case "updateRoom":
      return <Icon name="window close" color="red" />;
    default:
      return <Icon name="rocketchat" color="yellow" />;
  }
};

export default NotificationLabel;
