import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import AutoSizer from "react-virtualized-auto-sizer";

// const data = [
//     {id: "00", value: 10},
//     {id: "01", value: 20},
//     {id: "02", value: 50},
//     {id: "03", value: 40},
//     {id: "04", value: 30},
//     {id: "05", value: 20},
//     {id: "06", value: 10},
//     {id: "07", value: 5},
//     {id: "08", value: 50},
//     {id: "09", value: 30},
//     {id: "10", value: 40},
//     {id: "11", value: 20},
//     {id: "12", value: 70},
//     {id: "13", value: 80},
//     {id: "14", value: 100},
//     {id: "15", value: 120},
//     {id: "16", value: 20},
//     {id: "17", value: 40},
//     {id: "18", value: 60},
//     {id: "19", value: 70},
//     {id: "20", value: 80},
//     {id: "21", value: 90},
//     {id: "22", value: 140},
//     {id: "23", value: 60},
//   ];

const dataConverter = (objectData) => {
  let convertedData = [];
  for (const key in objectData) {
    if (objectData.hasOwnProperty(key)) {
      convertedData.push({ id: key, value: objectData[key] });
    }
  }
  return convertedData;
};

const StatisticsNumberOfChatsPerHourChart = (props) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height: 500, width: width }}>
          <ResponsiveBar
            data={dataConverter(props.data)}
            margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
            padding={0.3}
            colors={{ scheme: "nivo" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Hour",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "# of chats",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            // legends={[
            //     {
            //         dataFrom: 'keys',
            //         anchor: 'bottom-right',
            //         direction: 'column',
            //         justify: false,
            //         translateX: 120,
            //         translateY: 0,
            //         itemsSpacing: 2,
            //         itemWidth: 100,
            //         itemHeight: 20,
            //         itemDirection: 'left-to-right',
            //         itemOpacity: 0.85,
            //         symbolSize: 20,
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemOpacity: 1
            //                 }
            //             }
            //         ]
            //     }
            // ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      )}
    </AutoSizer>
  );
};

export default StatisticsNumberOfChatsPerHourChart;
