import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

const ModalsUserCloseOpenChat = (props) => (
  <Modal
    open={props.open}
    basic
    size="small"
    closeOnEscape={false}
    closeOnDimmerClick={false}
    onClose={props.closeModal}>
    <Header icon="archive" content="Chat Closed By End User" />
    <Modal.Content>
      <p>User has closed the chat. Please click "OK" to return.</p>
    </Modal.Content>
    <Modal.Actions>
      <Button color="green" inverted onClick={props.closeModal}>
        <Icon name="checkmark" /> OK
      </Button>
    </Modal.Actions>
  </Modal>
);

export default ModalsUserCloseOpenChat;
