import { GET_STATISTICS_DATA_SUCCESS } from "../actions";

const initialState = {
  statsData: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_STATISTICS_DATA_SUCCESS:
      return Object.assign({}, state, { statsData: action.payload.data });
    default:
      return state;
  }
}
