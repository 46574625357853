import React from "react";
import { Table } from "semantic-ui-react";

const StatisticsSummaryTable = (props) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell># of Days</Table.Cell>
          <Table.Cell>{props.data.numOfDays}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Total # of Chats Received</Table.Cell>
          <Table.Cell>{props.data.totalNumOfChatsReceived}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Average Chats Per Hour</Table.Cell>
          <Table.Cell>{props.data.averageChatsPerHour}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Average Chats Per Day</Table.Cell>
          <Table.Cell>{props.data.averageChatsPerDay}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default StatisticsSummaryTable;
