import React, { Component } from "react";
import { Menu, Label } from "semantic-ui-react";

const menuItemToUrl = {
  chatapp: "/chatapp",
  records: "/chatapp/records",
  users: "/chatapp/users",
  "add user": "/chatapp/adduser",
  profile: "/chatapp/profile",
  statistics: "/chatapp/statistics",
};

export default class Navigation extends Component {
  state = { activeItem: "chatapp" };

  handleItemClick = (e, { name }) => {
    if (name === "logout") {
      this.props.handleLogout();
    } else {
      this.setState({ activeItem: name });
      this.props.handleUrlChange(menuItemToUrl[name]);
    }
  };

  render() {
    const { activeItem } = this.state;
    const { connectedToSocket } = this.props;
    const connectedText = connectedToSocket ? "Connected" : "Disconnected";
    const connectedColor = connectedToSocket ? "green" : "red";
    return (
      <Menu inverted>
        <Menu.Item
          name="chatapp"
          active={activeItem === "chatapp"}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="records"
          active={activeItem === "records"}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="statistics"
          active={activeItem === "statistics"}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="users"
          active={activeItem === "users"}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="add user"
          active={activeItem === "add user"}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="profile"
          active={activeItem === "Profile"}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="logout"
          active={activeItem === "logout"}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="connected"
          active={activeItem === "connected"}
          onClick={() => console.log("")}
          position="right"
          disabled={true}>
          <Label circular color={connectedColor} content={connectedText} />
        </Menu.Item>
      </Menu>
    );
  }
}
