import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Header, Segment, Loader } from "semantic-ui-react";
import _ from "lodash";

import RecordsViewInfoTable from "../../component/records/view_info_table";
import RecordsViewMessagesTable from "../../component/records/view_messages_table";

class RecordsViewPage extends Component {
  state = {
    record: null,
  };
  componentDidMount() {
    console.log(this.props);
    const recordId = this.props.match.params.recordId;
    const record = _.find(
      this.props.records,
      (record) => record._id === recordId
    );
    // console.log(record);
    this.setState({ record });
  }
  render() {
    if (!this.state.record) {
      return <Loader active />;
    }
    return (
      <Grid columns={1}>
        <Grid.Column>
          <Header attached="top" block>
            Record Information
          </Header>
          <Segment attached>
            <RecordsViewInfoTable record={this.state.record} />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Header attached="top" block>
            Messages
          </Header>
          <Segment attached>
            <RecordsViewMessagesTable messages={this.state.record.messages} />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    records: state.rooms.records,
  };
};

export default connect(mapStateToProps)(RecordsViewPage);
