import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";

const StatisticsSlaTable = (props) => {
  const attendedBuckets = [0.5, 2, 4, 4.00000000001]; // in hours
  const notAttendedBuckets = [0.5, 2, 4, 24, 24.00000000001]; // in hours
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={3}>Chats</Table.HeaderCell>
          <Table.HeaderCell colSpan={4}>Attended</Table.HeaderCell>
          <Table.HeaderCell colSpan={5}>Not Attended</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>SN</Table.HeaderCell>
          <Table.HeaderCell>Account</Table.HeaderCell>
          <Table.HeaderCell># New Chats</Table.HeaderCell>
          <Table.HeaderCell>{"< 30 mins"}</Table.HeaderCell>
          <Table.HeaderCell>{"< 2 hrs"}</Table.HeaderCell>
          <Table.HeaderCell>{"< 4 hrs"}</Table.HeaderCell>
          <Table.HeaderCell>{"> 4 hrs"}</Table.HeaderCell>
          <Table.HeaderCell>After 30 mins</Table.HeaderCell>
          <Table.HeaderCell>After 2 hrs</Table.HeaderCell>
          <Table.HeaderCell>After 4 hrs</Table.HeaderCell>
          <Table.HeaderCell>In 24 hrs</Table.HeaderCell>
          <Table.HeaderCell>{"> 1 day"}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(props.data, (row, index) => {
          const { name, totalNumberOfChats, attended, notAttended } = row;
          return (
            <Table.Row key={index}>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>{name}</Table.Cell>
              <Table.Cell>{totalNumberOfChats}</Table.Cell>
              {_.map(attendedBuckets, (bucket, index) => {
                return <Table.Cell key={index}>{attended[bucket]}</Table.Cell>;
              })}
              {_.map(notAttendedBuckets, (bucket, index) => {
                return (
                  <Table.Cell key={index}>{notAttended[bucket]}</Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default StatisticsSlaTable;
