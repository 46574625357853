import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";

// const data = [
//     {id: "07 Oct 2019", value: 10, distributionValue: 10},
//     {id: "08 Oct 2019", value: 10, distributionValue: 10},
//     {id: "09 Oct 2019", value: 10, distributionValue: 10},
//     {id: "10 Oct 2019", value: 10, distributionValue: 10},
//     {id: "11 Oct 2019", value: 10, distributionValue: 10},
//     {id: "12 Oct 2019", value: 10, distributionValue: 10},
//     {id: "13 Oct 2019", value: 10, distributionValue: 10},
//     {id: "14 Oct 2019", value: 10, distributionValue: 10},
// ];

const dataConverter = (objectData) => {
  let convertedData = [];
  for (const key in objectData) {
    if (objectData.hasOwnProperty(key)) {
      convertedData.push({ id: key, value: objectData[key] });
    }
  }
  return convertedData;
};

const StatisticsNumberOfChatsPerDayTable = (props) => {
  const tableData = dataConverter(props.data);
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell># of Chats</Table.HeaderCell>
          <Table.HeaderCell>Distribution %</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(tableData, (row, index) => {
          const { id, value } = row;
          const distributionValue = (
            value / props.totalNumOfChatsReceived
          ).toFixed(2);
          return (
            <Table.Row key={index}>
              <Table.Cell>{id}</Table.Cell>
              <Table.Cell>{value}</Table.Cell>
              <Table.Cell>{distributionValue}%</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default StatisticsNumberOfChatsPerDayTable;
