import React from "react";
import { Input, Header, Message } from "semantic-ui-react";

// import './forms_inputs_text.css';

const FormsInputsText = ({ field, form: { touched, errors }, ...props }) => {
  const hasError = errors[field.name] && touched[field.name] ? true : false;
  return (
    <div className="form-input-group">
      <div>
        <Header sub>{props.label}</Header>
        {props.secondaryLabel ? (
          <Header.Subheader>{props.secondaryLabel}</Header.Subheader>
        ) : (
          ""
        )}
      </div>
      <Input
        fluid
        {...field}
        disabled={props.disabled ? props.disabled : false}
      />
      {hasError && <Message negative>{errors[field.name]}</Message>}
    </div>
  );
};

export default FormsInputsText;
