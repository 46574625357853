import React, { Component } from "react";
import { Card, Feed, Label, Icon } from "semantic-ui-react";
import _ from "lodash";

import NotificationItem from "./notification_item";

import { broadcastChatUpdates } from "../../utils/chat_api";

// import FormsUserUpdate from '../../component/forms/user/profile';
// import {
//     updateAdminUser
// } from '../../actions';

class NotificationBar extends Component {
  // selectedRoomToChat = (room) => {
  //   this.props.selectRoomToChat(room);
  //   broadcastChatUpdates(this.props.user.accessToken, room._id, () => {
  //     console.log('broadcastChatUpdates', this.props.user.accessToken, room._id);
  //   });
  // }
  render() {
    // console.log('NotificationBar', this.props);
    const hasSelectedRoom = this.props.selectedRoomData ? true : false;
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            &nbsp;&nbsp;Notifications&nbsp;&nbsp;
            <Label>{this.props.notifications.length}</Label>
          </Card.Header>
        </Card.Content>
        <Card.Content
          style={{
            minHeight: "700px",
            maxHeight: "700px",
            overflowX: "hidden",
            overflowY: "scroll",
          }}>
          <Feed size="small" style={{ padding: "5px" }}>
            {this.props.notifications.map((notification, index) => {
              const { type, data, updatedAt, uuid } = notification;
              return (
                <NotificationItem
                  key={uuid}
                  type={type}
                  data={data}
                  receivedAt={updatedAt}
                  hasSelectedRoom={hasSelectedRoom}
                  selectRoomToChat={this.props.selectRoomToChat}
                />
              );
            })}
          </Feed>
        </Card.Content>
      </Card>
    );
  }
}

export default NotificationBar;

// const mapStateToProps = state => {
//     return {
//         user: state.user.data,
//         notifications: state.notifications.notifications,
//     }
// }

// export default connect(mapStateToProps, {
//     // updateAdminUser
// })(NotificationBar);
